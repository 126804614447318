<script setup lang="ts">
const userState = useUser()
const { visible, logout } = useLogin()
const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()
const { ENV } = useRuntimeConfig().public

const userMenus = computed(() => {
  return [
    { path: localePath('/user/info'), text: t('个人信息') },
    { path: localePath('/favourites/product'), text: t('我的收藏') },
    { path: ENV === 'prod' ? 'https://sellerv1.glodastory.com' : location.origin, target: '_blank', text: t('切换旧版') },
    { text: t('退出登录') },
  ]
})

async function onDropdownClick(item: { path?: string, target?: string }) {
  if (item.path) {
    const openObj = item.target ? { open: { target: item.target } } : {}
    navigateTo(item.path, openObj)
  }
  else {
    // 退出登录
    logout()
  }
}
</script>

<template>
  <a-trigger
    auto-fit-position
    :popup-translate="[0, 8]"
    position="br"
  >
    <a-button v-if="!userState.info?.id" class="rd-28 text-16 font-500 !h-36 !bg-[--color-brand-6] !px-24 !text-white" @click="visible = true">
      {{ $t('登录注册') }}
    </a-button>
    <a-avatar v-else cursor-pointer class="bg-[--color-brand-6]" image-url="">
      G
    </a-avatar>
    <template v-if="userState.info?.id" #content>
      <div class="box-border min-w-264 rd-8 bg-white pb-8 shadow-[0_4px_16px_rgba(0,0,0,0.08)]">
        <div class="flex items-center gap-12 border-1 border-b-1 border-[--color-fill-1] border-b-solid p-20">
          <span class="h-48 w-48 flex flex-shrink-0 items-center justify-center rd-50% bg-[--color-brand-6] text-white font-500">G</span>
          <div flex flex-col gap-8>
            <span class="text-16 text-[--color-text-1] font-500">{{ userState.info?.name || userState.info?.email }}</span>
            <span class="text-12 text-[--color-text-4]">ID {{ userState.info?.id }}</span>
          </div>
        </div>
        <div flex flex-col px-8>
          <div
            v-for="item in userMenus"
            :key="item.text"
            class="mt-4 h-40 flex cursor-pointer items-center justify-between rd-4 px-12 text-[--color-text-1] hover:bg-[--color-fill-6]"
            :class="{ 'text-[--color-brand-6]! bg-[--color-brand-1]': item.path === route.path }"
            @click="onDropdownClick(item)"
          >
            <div flex-1>
              {{ item.text }}
            </div>
            <i class="icon-xiangyou" />
          </div>
        </div>
      </div>
    </template>
  </a-trigger>
</template>

<style scoped>
</style>
